<template>
	<div class="video-manage">
		<el-row class="function-area">
			<el-button type="primary" size="mini" @click="showUploadVideoDialog = true">手动上传</el-button>
			<div class="table-right-container">
				<div class="pull-right-buttons">
					<el-button-group>
						<el-tooltip content="刷新列表">
							<el-button @click="toGetVideoRecordList" icon="el-icon-refresh" size="small" type="info"></el-button>
						</el-tooltip>
					</el-button-group>
				</div>
				<div class="pull-right-search">
					<el-select @change="toGetVideoRecordList" clearable placeholder="频道筛选" size="small" style="width: 100px;margin-right: 5px;" v-model="searchParam.youtubeChannelId">
						<el-option v-for="item in youTubeChannels" :label="item['channelName']" :value="item['id']" :key="item['id']"></el-option>
					</el-select>
					<el-select @change="toGetVideoRecordList" clearable placeholder="任务筛选" size="small" style="width: 150px;" v-model="searchParam.taskId">
						<el-option :key="task['id']" :label="task['taskName']" :value="task['id']" v-for="task in taskList"></el-option>
					</el-select>
				</div>
			</div>

			<el-table :data="videoRecordList" border fit tooltip-effect="dark" v-loading="listLoading" height="467">
				<el-table-column prop="videoTitle" label="视频名称" show-overflow-tooltip width="350">
					<template slot-scope="scope">
						<el-link :href="scope.row['videoUrl']" type="primary" target="_blank">{{ scope.row['videoTitle'] }}</el-link>
					</template>
				</el-table-column>
				<el-table-column prop="running" label="是否成功" width="100">
					<template slot-scope="scope">
						<el-tag v-if="scope.row['uploadSuccess']" type="success">成功</el-tag>
						<el-tag v-else type="warning">失败</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="lastStatusText" label="最后状态" width="180"></el-table-column>
				<el-table-column prop="xiguaChannelName" label="西瓜频道" width="150"></el-table-column>
				<el-table-column prop="youtubeChannelName" label="YouTube频道" width="150"></el-table-column>
				<el-table-column prop="updateTime" label="更新时间" width="180"></el-table-column>
				<el-table-column prop="note" label="说明" show-overflow-tooltip width="130"></el-table-column>
				<el-table-column prop="uniqueId" label="唯一id" show-overflow-tooltip width="280"></el-table-column>
				<el-table-column label="操作" width="100">
					<template slot-scope="scope">
						<el-button @click.native.prevent="handleReUploadVideo(scope.$index)" type="text" size="small">重新上传</el-button>
					</template>
				</el-table-column>
			</el-table>

			<el-pagination :page-size.sync="searchParam.limit" :current-page.sync="searchParam.page" :page-count="totalPages" @size-change="toGetVideoRecordList" @current-change="toGetVideoRecordList"></el-pagination>
		</el-row>
		<el-dialog title="手动上传" :visible.sync="showUploadVideoDialog" width="500px">
			<el-form :model="uploadFormData" :rules="uploadFormRules" ref="uploadForm" label-width="110px" size="mini">
				<el-form-item label="主页链接" prop="videoUrl">
					<el-input v-model="uploadFormData.videoUrl" placeholder="请输入视频的西瓜链接"></el-input>
				</el-form-item>
				<el-form-item label="西瓜频道" prop="youtubeChannelId">
					<el-select v-model="uploadFormData.youtubeChannelId" placeholder="请选择频道">
						<el-option v-for="item in youTubeChannels" :label="item['channelName']" :value="item['id']" :key="item['id']"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="handleUploadVideo('uploadForm')" :loading="uploadLoading">确 定</el-button>
					<el-button @click="toResetForm">取 消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
import {getAllYouTubeChannels} from "@/api/channel";
import {getAllTasks} from "@/api/task";
import {getVideoRecords, reUploadVideo, uploadVideo} from "@/api/video";

export default {
	name: "VideoManage",
	data() {
		return {
			youTubeChannels: [],
			taskList: [],
			listLoading: false,
			searchParam: {youtubeChannelId: null, taskId: null, page: 1, limit: 10},
			totalPages: 0,
			videoRecordList: [],

			uploadFormData: {youtubeChannelId: null, videoUrl: null},
			uploadFormRules: {
				videoUrl: [{required: true, message: '请输入视频的西瓜链接', trigger: 'blur'}],
				youtubeChannelId: [{required: true, message: '请选择YouTube频道', trigger: 'change'}],
			},
			showUploadVideoDialog: false,
			uploadLoading: false
		}
	},
	created() {
		this.toGetAllYouTubeChannels();
		this.toGetAllTasks();
		this.toGetVideoRecordList();
	},
	methods: {
		toGetAllYouTubeChannels() {
			getAllYouTubeChannels().then(response => {
				if (!response['success']) return this.$message.error('获取YouTube频道失败：' + response.data);
				this.youTubeChannels = response.data;
			}).catch((error) => {
				this.$message.error('获取YouTube频道失败：' + error);
			});
		},
		toGetAllTasks() {
			// 获取所有任务列表
			getAllTasks().then(response => {
				if (!response['success']) return this.$message.error('获取Task列表失败：' + response.data);
				this.taskList = response.data;
			}).catch((error) => {
				this.$message.error('获取Task列表失败：' + error);
			});
		},

		toGetVideoRecordList() {
			this.listLoading = true;
			getVideoRecords(this.searchParam).then(response => {
				this.listLoading = false;
				if (!response['success']) return this.$message.error('获取发布记录失败：' + response.data);
				this.videoRecordList = response.data.list;
				this.totalPages = response.data.pages;
			}).catch((error) => {
				this.listLoading = false;
				this.$message.error('获取发布记录失败：' + error);
			});
		},
		handleReUploadVideo(index) {
			const videoUrl = this.videoRecordList[index].videoUrl;
			const youtubeChannelId = this.videoRecordList[index].youtubeChannelId;
			reUploadVideo(videoUrl, youtubeChannelId).then(response => {
				if (!response['success']) return this.$message.error('重传失败：' + response.data);
				this.$message.success('重传进行中，请关注记录状态');
			}).catch((error) => {
				this.listLoading = false;
				this.$message.error('重传失败：' + error);
			});
		},

		handleUploadVideo(formName) {
			this.$refs[formName].validate((valid) => {
				if (!valid) return false;

				this.uploadLoading = true;
				uploadVideo(this.uploadFormData.videoUrl, this.uploadFormData.youtubeChannelId).then(response => {
					this.uploadLoading = false;
					if (!response['success']) return this.$message.error('上传失败：' + response.data);
					this.$message.success(response.data);
					this.toResetForm();
				}).catch((error) => {
					this.uploadLoading = false;
					this.$message.error('上传失败：' + error);
				});
			});
		},
		toResetForm() {
			// 重置form
			this.uploadFormData = {youtubeChannelId: null, videoUrl: null};
			this.showUploadVideoDialog = false;
		}
	}
}
</script>

<style lang="less" scoped>
.video-manage {
	margin: 10px;

	.function-area {
		margin: 10px 0;
	}
}
</style>