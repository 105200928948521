import request from '@/utils/request';

//获取所有task
export function getAllTasks() {
    return request({
        url: '/api/v1/task',
        method: 'get'
    });
}

//添加新的task {taskName, taskRule, xiguaChannelId, youtubeChannelId, isValid, note}
export function addNewTask({taskName, taskRule, xiguaChannelId, youtubeChannelId, isValid, note}) {
    return request({
        url: '/api/v1/task',
        method: 'POST',
        data: {taskName, taskRule, xiguaChannelId, youtubeChannelId, isValid, note}
    });
}

//更新task  {id, taskName, taskRule, xiguaChannelId, youtubeChannelId, isValid, note}
export function updateTaskInfo({id, taskName, taskRule, xiguaChannelId, youtubeChannelId, isValid, note}) {
    return request({
        url: '/api/v1/task',
        method: 'put',
        data: {id, taskName, taskRule, xiguaChannelId, youtubeChannelId, isValid, note}
    });
}

//重启task
export function restartTask(id) {
    return request({
        url: '/api/v1/task/restart',
        method: 'POST',
        data: {id}
    });
}