import request from '@/utils/request';

//获取record 分页
export function getVideoRecords({youtubeChannelId, taskId, page, limit}) {
    let finalData = {page, limit};
    if (youtubeChannelId) finalData.youtubeChannelId = youtubeChannelId;
    if (taskId) finalData.taskId = taskId;
    return request({
        url: '/api/v1/video/record',
        method: 'get',
        params: finalData
    });
}

//获取record 根据url单个
export function getVideoRecordByVideoUrl(videoUrl) {
    return request({
        url: '/api/v1/video/record',
        method: 'get',
        params: {videoUrl}
    });
}

//手动上传新的视频
export function uploadVideo(videoUrl, youtubeChannelId) {
    return request({
        url: '/api/v1/video/upload',
        method: 'POST',
        data: {videoUrl, youtubeChannelId}
    });
}

//重新上传
export function reUploadVideo(videoUrl, youtubeChannelId) {
    return request({
        url: '/api/v1/video/reUpload',
        method: 'POST',
        data: {videoUrl, youtubeChannelId}
    });
}